import React from "react"
import _ from "lodash"

export default function ElzGDriveVideo({ videoGDriveId, posterImageUrl }) {
  return (
    <video
      controlsList="nodownload noremoteplayback"
      poster={posterImageUrl}
      controls
    >
      <source
        src={`https://drive.google.com/uc?export=download&id=${videoGDriveId}`}
        type="video/webm"
      />
      <source
        src={`https://drive.google.com/uc?export=download&id=${videoGDriveId}`}
        type="video/mp4"
      />
    </video>
  )
}
