import React, { useState } from "react"
import "./issue.scss"
import issue from "./issue.json"
import ElzGDriveVideo from "../../ElzGDriveVideo"

export default function Issues({ post }) {
  const issues = Object.keys(issue).includes(post.slug)
    ? issue[post.slug]
    : undefined
  const [openSection, setOpenSection] = useState("")

  return (
    <div className={`issues-issue-${post.slug}`}>
      {issues && issues.hero && (
        <div className="elzPhotoContainer">
          <ElzGDriveVideo
            videoGDriveId={issues.video}
            posterImageUrl={issues.hero}
          />
        </div>
      )}
      <div className="issues react-component-issues joelwashere">
        {issues && issues.subareas ? (
          issues.subareas.map((item, i) => (
            <div
              key={"issue-" + i}
              className={"issue-area name-" + item.heading}
            >
              <div
                onClick={() =>
                  openSection !== item.heading
                    ? setOpenSection(item.heading)
                    : setOpenSection("")
                }
                className="heading"
              >
                {item.heading}
              </div>
              {openSection === item.heading && (
                <div className="issue-detail">
                  {item.googleDriveVideoId && item.hero && (
                    <ElzGDriveVideo
                      videoGDriveId={item.googleDriveVideoId}
                      posterImageUrl={item.hero}
                    />
                  )}
                  {!item.googleDriveVideoId && item.hero && (
                    <img className="hero-section" src={item.hero} />
                  )}
                  <div className="copy">{item.copy}</div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="wp-issues joelwashere">
            <ElzGDriveVideo
              videoGDriveId={"1xFKrrx2Rs2VPmkyxk5hxNayDwRjNzKZr"}
              posterImageUrl={
                "https://wordpress.elizaforcolorado.com/wp-content/uploads/2022/09/Eliza-Hamrick_08072022_228-2048x1365.jpg"
              }
            />
            <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
          </div>
        )}
      </div>

      <div className="back-to-issues">
        <a href="/issues">⬅️ Back to Issues</a>
      </div>
    </div>
  )
}
