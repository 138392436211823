import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Endorsements from "./page/endorsements/endorsements"
import Issue from "./issues/issue/issue"
import Issues from "./issues/issues"
import { photos } from "./photo-map.json"

import "./elz-page.scss"
import "./page/homepage/homepage.scss"
const IS_NEW_HOMEPAGE = process.env.GATSBY_HAMRICK_NEW_HOMEPAGE || false

const BANNED_PAGES = ["june-24"]

const ISSUES_SUBPAGES = [
  "education",
  "health-and-safety",
  "thriving-communities",
]

const HEADER_PHOTOS_BY_SLUG = {
  contact: {
    url: photos.firefighters.f4.url,
    alt: photos.firefighters.f4.alt,
  },
  "join-us": {
    url: photos.firefighters.f3.url,
    alt: photos.firefighters.f3.alt,
  },
  education: {
    url: `https://wordpress.elizaforcolorado.com/wp-content/uploads/2022/09/Eliza-Hamrick_08072022_228-scaled.jpg`,
  },
}

export default function ElzPage({ data }) {
  if (!!!data) {
    return (
      <Layout>
        <div>Oops--there's nothing here.</div>
      </Layout>
    )
  } else {
    const post = data.allWpPage.nodes[0]
    if (BANNED_PAGES.includes(post?.slug)) {
      return <Layout></Layout>
    }
    console.log({
      SLUG: post.slug,
      IS_NEW_HOMEPAGE,
      BANNED_PAGES,
      ISSUES_SUBPAGES,
    })
    if (post.slug === "home" && IS_NEW_HOMEPAGE) {
      return <Layout></Layout>
    } else if (post.slug === "endorsements") {
      return (
        <Layout>
          <SEO title={post.title} description={post.title} />
          <Endorsements />
        </Layout>
      )
    } else if (post.slug === "issues") {
      return (
        <Layout>
          <SEO title={post.title} description={post.title} />
          <Issues post={post} />
        </Layout>
      )
    } else if (ISSUES_SUBPAGES.includes(post.slug)) {
      return (
        <Layout>
          <h1>{post.title}</h1>
          <SEO title={post.title} description={post.title} />
          <Issue post={post} />
        </Layout>
      )
    }
    console.log({ ...HEADER_PHOTOS_BY_SLUG[post.slug] })

    return (
      <Layout>
        <SEO title={post.title} description={post.title} />
        <div>
          <h1>{post.title}</h1>
          {post.slug in HEADER_PHOTOS_BY_SLUG && (
            <div className="elzPhotoContainer">
              <img
                src={HEADER_PHOTOS_BY_SLUG[post.slug].url}
                alt={
                  HEADER_PHOTOS_BY_SLUG[post.slug].alt ||
                  "Eliza with constituents."
                }
                placeholder="blurred"
                loading="eager"
                className="elzPhoto"
              />
            </div>
          )}
          <div
            className="wp-html"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query ($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        slug
      }
    }
  }
`
