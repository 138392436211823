import React from "react"
import "./endorsement-photo.scss"

const EndorsementPhoto = ({ endorsement }) => {
    const PLACEHOLDER_PHOTO = "https://wordpress.elizaforcolorado.com/wp-content/uploads/2022/06/ElizaHamrick_Houser_ev-01.png";

    return (
        <div className={`endorsement-photo ${endorsement.organization && 'org-true'}`}>
            <div className="photo">
                <img 
                    src={endorsement.photo || PLACEHOLDER_PHOTO}
                    alt={endorsement.name}
                />
            </div>
            <div className="name">
                <span>{endorsement.prefix && (<span className="prefix">{endorsement.prefix}&nbsp;</span>)}
                {endorsement.name}</span>    
            </div>
            {endorsement.title && (<div className="title">{endorsement.title}</div>)}
            {endorsement.district && (<div className="district">{endorsement.district}</div>)}
            {endorsement.override && (<div className="override">{endorsement.override}</div>)}
        </div>
    );
}

export default EndorsementPhoto
