import React from "react"
import "./endorsements.scss"
import _ from "lodash"
import EndorsementPhoto from "./photo/endorsement-photo"
import EndorsementHero from "./hero/endorsement-hero"
import EndorsementWide from "./wide/endorsement-wide"
import endorsements from "./endorsements.json"
import { photos } from "../../photo-map.json"


export default function Endorsements() {

    const allEndorsements = endorsements.endorsements;

    const heroes = endorsements.metadata.hero
        .filter(org => _.has(allEndorsements, org) )
        .map(org => allEndorsements[org])
        .filter(org => _.has(org, 'name'));

    const organizations = endorsements.metadata.organization
        .filter(org => _.has(allEndorsements, org) )
        .map(org => allEndorsements[org])
        .filter(org => _.has(org, 'name'));

    const individuals = endorsements.metadata.individual
        .filter(org => _.has(allEndorsements, org) )
        .map(org => allEndorsements[org])
        .filter(org => _.has(org, 'name'));

    return (
        <div className="endorsements">
            <h1>Endorsements &amp; Support</h1>
            <div className="elzPhotoContainer">
                    <img 
                        src={photos.firefighters.f1.url} 
                        alt="Eliza meets with fire fighters." 
                        placeholder="blurred"
                        loading="eager"
                        className="elzPhoto"
                    />
                </div>
            <div>
                <p>I am proud to have the support of the following individuals and organizations.</p>
            </div>
            <hr className="top-hr" />
            <div className="organizations">
                <h2>Organizations</h2>
                <div className="endorsers">
                    {organizations
                        .sort( (a, b) => 
// no types! (a.type || 'unknown').localeCompare(b.type || 'unknown') ||
                                          a.name.localeCompare(b.name) 
                        )
                        .map((item, i) => (
                        <EndorsementWide key={"org-" + i} endorsement={item} />
                    ))}
                </div>
            </div>
            <hr />
            <div className="individuals">
                <h2>Individuals</h2>
                <div className="heroes">
                    {heroes
                        .map((item, i) => (
                        <EndorsementHero key={"hero-" + i} endorsement={item} />
                    ))}
                </div>
                <div className="endorsers">
                    {individuals
                        .map((item, i) => (
                        <EndorsementPhoto key={"ind-" + i} endorsement={item} />
                    ))}
                </div>
            </div>
        </div>
    );
}
