import React from "react"
import "./issues.scss"

export default function Issues({ post }) {
  return (
    <div className="issues">
      <h1>Issues</h1>

      <div className="elzPhotoContainer">
        <img
          src={`https://wordpress.elizaforcolorado.com/wp-content/uploads/2022/09/Eliza-Hamrick_08072022_89-scaled.jpg`}
          alt="Eliza meets with fire fighters."
          placeholder="blurred"
          loading="eager"
          className="elzPhoto"
        />
      </div>

      {/* Pull content from Wordpress. */}
      <div dangerouslySetInnerHTML={{ __html: post.content }}></div>

      <div className="issues-categories">
        <a className="myButton" href="/education">
          Education
        </a>
        <a className="myButton" href="/health-and-safety">
          Health and Safety
        </a>
        <a className="myButton" href="/thriving-communities">
          Thriving Communities
        </a>
      </div>
      <div className="cta">
        <span>
          I want to hear from you! What issues are most important to you?{" "}
          <a href="/contact">Let me know!</a>
        </span>
      </div>
    </div>
  )
}
